<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-31 11:39:13
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-06-21 09:46:25
 * @FilePath: \cz-operation-web\src\views\dataManagement\processingLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dataProcessingLog">
    <div class="card_title">数据处理：{{ processingInfo.name || '--' }}</div>
    <a-table
      :dataSource="processingLogList"
      :columns="columns"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      @change="tableChange"
    >
      <template slot="status" slot-scope="text, record">
        <span :class="`status-${text}`">{{ statusText(text) }}</span>
        <span v-if="text == '2' && record.errorMessage"
          >({{ record.errorMessage }})</span
        >
      </template>

      <template slot="runUser" slot-scope="text, record">
        <div class="creator-container">
          <u-avatar
            class="creator-img"
            :picUrl="record.avatar"
            :name="record.runUser"
            width="30px"
            height="30px"
            style="margin-right: 10px"
          ></u-avatar>
          <span class="creator-name">{{ record.runUser }}</span>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: '编号',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'left',
    width: '200px',
  },
  {
    title: '启动方式',
    dataIndex: 'startWay',
    key: 'startWay',
    scopedSlots: { customRender: 'startWay' },
    align: 'left',
    width: '120px',
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    key: 'startTime',
    scopedSlots: { customRender: 'startTime' },
    align: 'left',
    width: '200px',
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    key: 'endTime',
    scopedSlots: { customRender: 'endTime' },
    align: 'left',
    width: '200px',
  },

  {
    title: '运行时长',
    dataIndex: 'runTime',
    key: 'runTime',
    scopedSlots: { customRender: 'runTime' },
    align: 'left',
    width: '80px',
    width: '150px',
  },

  {
    title: '运行结果',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'left',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
    align: 'left',
  },

  {
    title: '操作人',
    dataIndex: 'runUser',
    key: 'runUser',
    scopedSlots: { customRender: 'runUser' },
    align: 'left',
    fixed: 'right',
    width: '200px',
  },
]

export default {
  name: 'ProcessingLog',
  data() {
    return {
      processingLogList: [],
      processingInfo: {},
      columns,
      statusLists: [
        { label: '未运行', value: '0' },
        { label: '运行正常', value: '1' },
        { label: '运行错误', value: '2' },
      ],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },
    }
  },
  created() {
    // this.$api.jobLog(this.$route.query.id).then((res) => {
    //   console.log('被输出值{ 日志 }的输出结果是：', res)
    // })
    this.getProcessingInfo()
    this.getDataProcessingLogList()
  },
  computed: {
    statusText() {
      return function (value) {
        const target = this.statusLists.find((item) => item.value === value)
        return target ? target.label : '-'
      }
    },
  },
  methods: {
    /* 表格变化（分页） */
    tableChange(pagination) {
      let { current, pageSize } = pagination
      // this.pagination.current = current
      // this.pagination.pageSize = pageSize
      let params = {
        limit: pageSize,
        page: current,
      }
      this.getDataProcessingLogList(params)
    },
    /* 获取数据处理日志列表 */
    async getDataProcessingLogList(params = { page: 1, size: 10 }) {
      try {
        // params = { ...params, flow: this.$route.query.id }
        const flowId = this.$route.query.id
        let res = await this.$api.getDataProcessingLogList(params, flowId)
        if (res.code === 0) {
          this.processingLogList = res.data.records
          this.pagination.total = Number(res.data.total)
          this.pagination.current = res.data.page
          this.pagination.pageSize = res.data.limit
        }
      } catch (err) {
        console.log('[ err ] >', err)
      }
    },
    /* 获取数据处理信息 */
    async getProcessingInfo() {
      try {
        let res = await this.$api.getDataProcessingInfo(this.$route.query.id)
        if (res.code === 0) {
          this.processingInfo = res.data
        }
      } catch (err) {
        console.log('[ err ] >', err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.dataProcessingLog {
  background-color: #fff;
  padding: 15px;
}

.card_title {
  font-family: 'PingFangSC Medium';
  // font-weight: 500;
  font-size: 15px;
  text-align: left;
  color: #292929;
  font-weight: bold;
  margin-bottom: 10px;
}

/deep/ .ant-table-align-left {
  height: 44px !important;
  padding: 0;
  padding-left: 16px;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

.status-1 {
  color: #52c41a;
}
.status-2 {
  color: #f5222d;
}
</style>
